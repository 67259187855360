@import "../../assets/styles/index.scss";

.card-projet {
  border-radius: 5px;
  padding: 10px;
}

.btn-tarif {
  color: white;
  text-transform: none !important;
  font-weight: bold !important;
  cursor: pointer;
  font-family: "verdana";
  background-color: $green !important;
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 8px 15px 8px 15px !important;
  font-size: 17px !important;
}

.radio-gender {
  padding: 4px 5px 4px 15px !important;
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15) !important;
}