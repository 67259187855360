body{
    background-color: #fff;
}

.list-documents-upload{
    height: 35vh;
    overflow: auto;
}


.disabled-btn-valid {
    color: white !important;
    background-color: #bebebe !important;
    text-transform: none !important;
    font-weight: bold !important;
    font-family: "verdana";
}

.type-doc:hover{
    background-color: #3f51b5 !important;
    color: #fff;
}

.item-doc, .MuiListItemText-root.css-tlelie-MuiListItemText-root{
    border : 1px solid #dedede;
    border-radius: 5px;
}

.produit {
    color: #fff;
    padding: 1rem;
    border-radius: 5px;
    font-weight: 700;
    &.produit-1{
        background-color: #344DED;
    }

    &.produit-2{
        background-color: #EF5858;
    }
    
    &.produit-7{
        background-color: #eacf62;
    }

    &.produit-8{
        background-color: #6b5eae;
    }

    &.produit-5{
        background-color: #BBD148;
    }

    &.produit-6{
        background-color: #5367F0;
    }

    &.produit-13{
        background-color: #39afd1;
    }

    &.produit-14{
        background-color: #fd7e14;
    }
}