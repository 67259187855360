@import "../../assets/styles/index.scss";

.stepper-emp .MuiStepLabel-label.Mui-completed {
  font-family: "century-bold" !important;
}

.stepper-emp .MuiStepLabel-label.Mui-active {
  font-family: "century-bold";
}

.css-1hv8oq8-MuiStepLabel-label {
  color: #646464 !important;
  font-family: "century-bold" !important;
}

.stepper-emp {
  margin-top: -21px;
}

.stepper-emp .MuiStep-root:first-child {
  padding-left: 0px !important;
  display: flex !important;
  align-items: flex-start !important;
}

.stepper-emp .MuiStep-root:last-child {
  padding-right: 0px !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.stepper-emp .MuiStepLabel-root:first-child {
  align-items: flex-start !important;
}

.stepper-emp .MuiStep-root:last-child .MuiStepLabel-root {
  align-items: flex-end !important;
}

.stepper-emp .MuiStepConnector-line {
  border-color: transparent;
}

.stepper-emp .MuiStepIcon-root circle {
  color: #eee !important;
}

.stepper-emp .MuiStepIcon-root.Mui-active circle,
.stepper-emp .MuiStepIcon-root.Mui-completed path {
  color: $green !important;
}