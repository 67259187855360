@import "../../assets/styles/index.scss";

.bg-about {
    background-image: linear-gradient(rgba(47, 76, 135, 0.8), rgba(47, 76, 135, 0.8)), url(../../assets/images/AC2A4178.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        background: url(../../assets/images/design.svg);
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: scaleX(-1);
        opacity: 0.8;
    }
}

.box-flottant {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 26.786399841308594px 117.19049835205078px 0px rgba(32, 36, 138, 0.06);
    z-index: 1;
}

.histoire {
    background-color: $blue;
    position: relative;
    z-index: 1;
    margin-bottom: 5%;

    &::before {
        content: "";
        background: url(../../assets/doodles/courbe.svg);
        background-repeat: no-repeat;
        background-position: bottom right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &::after {
        content: "";
        background: url(../../assets/images/design.svg);
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: scaleX(-1);
    }

    .box-white {
        padding: 44px;
        border-radius: 20px;
        box-shadow: 0px 26px 117px 0px rgba(32, 36, 138, 0.06);
        background-color: #fff;
    }
}


@media (max-width: 900px) {
    .bg-about {
        border-radius: 0 0 80% 80% / 40px;

        &::after {
            background: none;
        }
    }

    .histoire {
        margin-bottom: 0%;

        &::before {
            background: none;
        }
    }
}