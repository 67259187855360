@import "../../assets/styles/index.scss";


.half-background {
    position: relative;
}

.half-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 56%;
    background-color: $blue1;
    z-index: -1;
}

.btn-alert-pink {
    color: white;
    text-transform: none !important;
    font-weight: bold !important;
    cursor: pointer;
    font-family: "verdana";
    background-color: $pink !important;
    box-shadow: none !important;
    border-radius: 50px !important;
    font-size: 18px !important;
}

.radius-left {
    border-top-left-radius: 20px;
}

.bg-join {
    background-color: $blue;
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        background: url(../../assets/images/design.svg);
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: scaleX(-1);
    }
}


@media (max-width: 900px) {
    .half-background::before {
        height: 74%;
        border-radius: 0 0 80% 80% / 40px;
    }
}