@import "../../assets/styles/index.scss";


.cgu p {
    color: #4a4a4a;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 19px;
    font-weight: 400;
    text-align: left;
}

.cgu h2 {
    color: #4a4a4a;
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;
    padding-top: 15px;
    margin-bottom: 18px;
    text-align: left;
    padding: 0px;
    font-family: "asar" !important;
}