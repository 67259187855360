@import "../../assets/styles/index.scss";

.Mui-checked {
    color: $blue;
}

.radio-item {
    padding: 4px 5px 4px 15px !important;
    border-radius: 6px !important;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15) !important;
}

.MuiFormGroup-row {
    margin-left: 0px !important;
}

.MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px !important;
}

.MuiFormControl-root .css-i4bv87-MuiSvgIcon-root {
    color: $blue !important;
}

.MuiInputBase-input {
    height: 18px !important;
}

.google-places-autocomplete {
    display: flex !important;
    flex-wrap: wrap;
}

.google-places-autocomplete__input, .rib-adresse-autocomplete__input {
    height: 47px !important;
	border-radius: 4px;
	font-size: 18px !important;
	border: 1px solid #c4c4c4 !important;
	padding-left: 14px !important;
	padding-right: 14px !important;
	word-break: break-all;
	text-overflow: ellipsis;
    width: 100% !important;
}

.google-places-autocomplete__input:hover, .rib-adresse-autocomplete__input:hover {
    border: 1px solid black !important;
}

.google-places-autocomplete__input:focus, .rib-adresse-autocomplete__input:focus {
    border: 2px solid $blue !important;
    height: 45px !important;
    outline: none !important;
}

.suggestions-container {
	position: absolute;
	z-index: 999;
	background-color: white;
	box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
    margin-top: 51px !important;
}

.MuiSelect-outlined {
    min-height: 18px !important;
}

.MuiFormControlLabel-label {
    color: #a3a3a3 !important;
}

.radio-item .MuiFormControlLabel-label:hover {
    color: $blue !important;
}

.number-select {
    padding: 6px 11px 6px 12px;
    border-radius: 2px;
    border: 2px solid #BEBEBE;
    font-size: 14px;
    color: #a3a3a3;
    cursor: pointer;
}

.number-active {
    border: 2px solid $blue2;
    color: white !important;
    background-color: $blue2 !important;
    cursor: default !important;
}

.step-1-form {
    width: 230px;
}

.MuiTableCell-root {
    border-bottom: none !important;
    padding-left: 0px !important;
}

.rc-slider-mark-text {
    transform: translate(-50%, -60px) !important;
    font-family: "verdana";
    font-style: normal;
    font-size: 16px;
    color: $dark;
    white-space: nowrap !important;
}


.rc-slider-dot {
    border-color: #ebebeb;
    background-color: #ebebeb;
    height: 18px;
    width: 18px;
    bottom: -7px;
}

.rc-slider-dot-active {
    border-color: $blue ;
    background-color: $blue ;
    height: 18px;
    width: 18px;
    bottom: -7px;
}

.rc-slider-handle {
    border: none;
    background-color: transparent;
}

.rc-slider-handle-dragging {
    border-color: transparent !important;
    box-shadow: none !important;
}

.rc-slider-track {
    background-color: $blue ;
}

.btn-modifier {
    text-decoration: underline;
    cursor: pointer;
    color: $dark;
    font-size: 15px;
    font-family: "century";
}

.plus-formule {
    ul {
        list-style: disc;
        margin-top: 30px;

        li {
            font-size: 13px;
            margin-bottom: 10px;
        }
    }
}

.modify {
    margin-top: 15px;
    text-decoration: underline;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    font-family: "century";

    &:hover {
        opacity: 0.8;
    }
}

.point-fort {
    h2 {
        font-weight: 900;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    ul {
        li {
            font-size: 13px;
            margin-bottom: 10px;

            span {
                color: $blue;
            }
        }
    }
}

.btn-group {
    border: 2px solid #eceeff !important;
    text-transform: none !important;
    font-family: "verdana" !important;
    padding: 15px 25px 15px 25px !important;
    color: $dark !important;
}

.btn-group-active {
    border: none !important;
    text-transform: none !important;
    font-family: "verdana" !important;
    padding: 15px 25px 15px 25px !important;
    color: $dark !important;
    background-color: #eceeff !important;
}

.link {
    text-decoration: underline !important;
    color: $blue !important;
}

.checkbox-label {
    font-family: "verdana" !important;
}