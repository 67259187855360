@import "../../assets/styles/index.scss";

.card-simple {
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 16px 24px 0px rgba(48, 49, 51, 0.1),
    0px 0px 1px 0px rgba(48, 49, 51, 0.05);
  background-color: white;
}

.card-assure {
  border: 3px solid #88888850;
  cursor: pointer;
}

.card-assure:hover {
  border: 3px solid $blue3;
}

.card-assure-blue {
  border: 3px solid $blue3;
  background-color: #a1b7de25;
}