@import "../../assets/styles/index.scss";

.btn-decouvrir-blue {
    color: white;
    text-transform: none !important;
    font-weight: bold !important;
    cursor: pointer;
    font-family: "verdana";
    background-color: $blue1 !important;
    box-shadow: none !important;
    border-radius: 50px !important;
    padding: 15px 25px 15px 25px !important;
    font-size: 18px !important;
}

.btn-decouvrir-pink {
    color: white;
    text-transform: none !important;
    font-weight: bold !important;
    cursor: pointer;
    font-family: "verdana";
    background-color: $pink !important;
    box-shadow: none !important;
    border-radius: 50px !important;
    font-size: 18px !important;
}

.half-background-emprunteur {
    position: relative;
    
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70%;
        background-color: $blue;
        z-index: -1;
    }
}

.bg-emprunteur {
    background-color: $blue;
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        background: url(../../assets/images/design.svg);
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: scaleX(-1);
    }
}