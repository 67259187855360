@import "../../assets/styles/index.scss";


.btn-recevoir-blue {
    color: white;
    text-transform: none !important;
    font-weight: bold !important;
    cursor: pointer;
    font-family: "verdana";
    background-color: $blue1 !important;
    box-shadow: none !important;
    border-radius: 50px !important;
    padding: 15px 25px 15px 25px !important;
    font-size: 18px !important;
}

.btn-recevoir-pink {
    color: white;
    text-transform: none !important;
    font-weight: bold !important;
    cursor: pointer;
    font-family: "verdana";
    background-color: $pink !important;
    box-shadow: none !important;
    border-radius: 50px !important;
    font-size: 18px !important;
}

.radius-right {
    border-top-right-radius: 20px;
}