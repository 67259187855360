@import "../../assets/styles/index.scss";

.card-parcours {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
}

.btn-suivant {
  color: white;
  text-transform: none !important;
  font-weight: bold !important;
  cursor: pointer;
  font-family: "verdana";
  background-color: $blue1 !important;
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 15px 24px 15px 24px !important;
  font-size: 18px !important;
}

.disabled-btn {
  color: white !important;
  background-color: #bebebe !important;
  text-transform: none !important;
  font-weight: bold !important;
  font-family: "verdana";
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 15px 24px 15px 24px !important;
  font-size: 18px !important;
}

.btn-precedent {
  color: $pink !important;
  text-transform: none !important;
  font-weight: bold !important;
  cursor: pointer;
  font-family: "verdana";
  box-shadow: none !important;
  font-size: 18px !important;
  background-color: transparent !important;
}

.btn-precedent-bleu {
  color: $blue1 !important;
  text-transform: none !important;
  font-weight: bold !important;
  cursor: pointer;
  font-family: "verdana";
  box-shadow: none !important;
  font-size: 18px !important;
  background-color: transparent !important;
}

.btn-precedent-branding {
  text-transform: none !important;
  font-weight: bold !important;
  cursor: pointer;
  font-family: "verdana";
  box-shadow: none !important;
  font-size: 18px !important;
  background-color: transparent !important;
}

.btn-suivant-branding {
  color: white !important;
  text-transform: none !important;
  font-weight: bold !important;
  cursor: pointer;
  font-family: "verdana";
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 15px 24px 15px 24px !important;
  font-size: 18px !important;
}

.MuiBreadcrumbs-separator {
  margin: 0px !important;
}

.breadcumb-active {
  color: $blue1 !important;
  cursor: default;
}

.bandeau {
  background: #fff;
  position: fixed;
  z-index: 9999;
  bottom: 0px;
  width: 100%;
  -webkit-box-shadow: 0 4px 5px 6px #ccc;
  -moz-box-shadow: 0 4px 5px 6px #ccc;
  box-shadow: 0 4px 5px 6px #ccc;
}
