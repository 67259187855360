@import "../../assets/styles/index.scss";

.substep-section {
  width: 100%;
  max-width: 768px;
  margin: auto;
  // background: #fff;
  // border-radius: 20px;
  // box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
}

.type-select {
  border: 1px solid #eae9e3;
  background-color: #a3a3a308;
  cursor: pointer;
}

.type-select-bank {
  border: 1px solid #eae9e3;
  background-color: white;
  cursor: pointer;
}

.form-section {
  border-radius: 15px;
  // box-shadow: 0px 12px 32px 0px #00000017;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1019607843);
}

.box-border {
  border: 3px solid #6691ca43;
}

.box-border-disabled {
  filter: grayscale(100%) !important;
  border: 3px solid #494a4b43;
  opacity: 0.6;
  pointer-events: none;
}

.box-border-emp {
  border: 3px solid #6691ca43;
  background-color: #a1b7de25;
}

.btn-souscription {
  color: white;
  text-transform: none !important;
  font-weight: bold !important;
  cursor: pointer;
  font-family: "verdana";
  background-color: $blue1 !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  padding: 15px 24px 15px 24px !important;
  font-size: 18px !important;
}

.card-assure-emp {
  cursor: pointer;
  transition-duration: 0.5s;
  border: 4px solid white;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1019607843);
  background-color: white;
}

.card-assure-emp:hover {
  border: 4px solid $blue3;
  background-color: #a1b7de25;
  box-shadow: 9px 12px 18px rgba(0, 0, 0, 0.1019607843);
  transition-duration: 0.5s;
}

.card-assure-emp-blue {
  border: 4px solid $blue3;
  background-color: #a1b7de25;
  box-shadow: 9px 12px 18px rgba(0, 0, 0, 0.1019607843);
}

.card-bank-emp {
  cursor: pointer;
  transition-duration: 0.5s;
  border: 4px solid white;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1019607843);
  background-color: white;
}

.card-bank-emp:hover {
  border: 4px solid $blue3;
  box-shadow: 9px 12px 18px rgba(0, 0, 0, 0.1019607843);
  transition-duration: 0.5s;
}

.card-bank-emp-blue {
  border: 4px solid $blue3;
  box-shadow: 9px 12px 18px rgba(0, 0, 0, 0.1019607843);
  background-color: white;
}

.btn-suivant-emp {
  color: white;
  text-transform: none !important;
  cursor: pointer;
  font-family: "century-bold" !important;
  background-color: $blue1 !important;
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 12px 24px 12px 24px !important;
  font-size: 18px !important;
}

.disabled-btn-emp {
  color: white !important;
  background-color: #bebebe !important;
  text-transform: none !important;
  font-family: "century-bold" !important;
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 12px 24px 12px 24px !important;
  font-size: 18px !important;
}

.btn-precedent-emp {
  color: $pink !important;
  text-transform: none !important;
  cursor: pointer;
  font-family: "century-bold" !important;
  box-shadow: none !important;
  font-size: 18px !important;
  background-color: transparent !important;
}

.input-emp .MuiInputBase-root,
.input-emp .MuiSelect-select,
.input-emp {
  border-radius: 12px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  background-color: white !important;
}

.input-emp .MuiInputBase-input {
  font-family: "century" !important;
}

.input-emp fieldset {
  border: 1px solid #d9dee3 !important;
  box-shadow: 0 4px 8px rgba(172, 186, 200, 0.1) !important;
}

.radio-group .MuiFormControlLabel-label {
  color: #25201c !important;
  margin-right: 0px !important;
}

.radio-emp {
  cursor: pointer;
  transition-duration: 0.5s;
  border: 3px solid white;
  box-shadow: 0 4px 8px rgba(172, 186, 200, 0.1) !important;
  background-color: white;
}

.radio-emp:hover {
  border: 3px solid $blue3;
  background-color: #a1b7de15;
  box-shadow: 0 4px 8px rgba(172, 186, 200, 0.1) !important;
  transition-duration: 0.5s;
}

.radio-emp-blue {
  border: 3px solid $blue3;
  background-color: #a1b7de15;
  box-shadow: 0 4px 8px rgba(172, 186, 200, 0.1) !important;
}

.MuiFormControlLabel-root.radio-emp,
.MuiFormControlLabel-root.radio-emp-blue {
  margin: 0px !important;
  font-size: 15px !important;
}

.adresse-emp .google-places-autocomplete__input {
  border-radius: 12px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s3 ease-in-out !important;
  background-color: white !important;
  border: 1px solid #d9dee3 !important;
  box-shadow: 0 4px 8px rgba(172, 186, 200, 0.1) !important;
  font-family: "century" !important;
  font-size: 17px !important;
}
