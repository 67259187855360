@import "../../assets/styles/index.scss";

.btn-offre-blue {
  color: white;
  text-transform: none !important;
  font-weight: bold !important;
  cursor: pointer;
  font-family: "verdana";
  background-color: $blue1 !important;
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 15px 25px 15px 25px !important;
  font-size: 18px !important;
}

.btn-offre-pink {
  border-radius: 30px !important;
  border: 3px solid $pink !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15) !important;
  font-family: "verdana" !important;
  border-radius: 50px !important;
  padding: 15px 25px 15px 25px !important;
  font-size: 18px !important;
  color: $pink !important;
  text-transform: none !important;
  font-weight: bold !important;
}

.bg-home {
  background-color: $blue;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    background: url(../../assets/images/design.svg);
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.bg-engagement {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    background: url(../../assets/images/design2.svg);
    background-repeat: no-repeat;
    background-position: right;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.card-engagement {
  box-shadow: 0px 16px 24px 0px rgba(48, 49, 51, 0.1),
    0px 0px 1px 0px rgba(48, 49, 51, 0.05);
  background-color: white;
}

.paper-join {
  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
}

.box-confiance {
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
}

.home-section {
  border-radius: 12px;
  background-image: url("../../assets/images/AC2A4376.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.expertise-card {
  position: relative;
  z-index: 1;
}

.expertise-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  border-radius: 20px;
  filter: brightness(0.50);
  transition-duration: 0.5s;
}

.expertise-card-sante::before {
  background-image: url("../../assets/images/assurance_santé.png");
}

.expertise-card-emprunteur::before {
  background-image: url("../../assets/images/assurance_prêt.png");
}

.expertise-card-prevoyance::before {
  background-image: url("../../assets/images/assurance_prevoyance.png");
}

.expertise-card:hover::before {
  filter: brightness(0.35);
  transition-duration: 0.5s;
}

.half-background-home {
  position: relative;
}

.half-background-home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: $blue;
  z-index: -1;
}

@media (max-width: 900px) {
  .home-section {
    border-radius: 12px;
    background-image: url("../../assets/images/femme_julia.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .paper-join {
    box-shadow: none !important;
  }
}
